import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import TitleBlock from "../components/titleBlockPages"
import Catalogues from "../components/catalogues"
import ContactForm from "../components/footerContactBlock"


const PromoClothing = ({ data }) => {
  ///page details
  const pageDataEdges = data.allPagecataloguesJson.edges[0].node;
  const catalogueData = data.allCataloguesJson.edges;
  const catalogues = catalogueData.map((item) => { 
    return item.node;
  })
  return (
    <Layout>
      <Seo
        title="View The Full Range Of Customisable Products"
        description="From t-shirts to mugs and workwear to uniforms we can customise what you need for your business. Download the brochures now >>"
      />
      <TitleBlock
        pageTitle={pageDataEdges.pageTitle}
        pageSubTitle={pageDataEdges.pageSubTitle}
        pageTitleBody={pageDataEdges.pageTitleBody}
        img={pageDataEdges.pageTitleImg}
        cta={true}
      />
      <Catalogues cats={ catalogues} />
      <ContactForm />
    </Layout>
  )
}
export const query = graphql`
  query MyQuery {
    
    allCataloguesJson {
    edges {
      node {
        catTitle
        catLink
        bgimage {
          childImageSharp {
              gatsbyImageData(
                width: 400
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
          }
        }
      }
    }
  }

    allPagecataloguesJson {
      edges {
        node {
          pageTitleBody
          pageTitle
          pageSubTitle
          pageTitleImg {
                childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
              }
          
        }
      }
    }
}`
export default PromoClothing