import React, { Component } from "react";
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default class SimpleSlider extends Component {

    render() {
        const catalogues = this.props.cats
        return (
            <div className="container py-0 py-md-5" >
                <div className="row g-3">
                {catalogues.length ? (
                    
                    catalogues.map((cat, index) => {
                        const loadedImage = getImage(cat.bgimage)
                        
                        return (
                            <div
                                className="col-6 col-sm-6 col-md-6 col-lg-3 mb-3"
                                key={index}
                                data-sal="slide-up"
                                data-sal-delay="300"
                                data-sal-easing="ease"
                            >
                                <h4 className="fw-light fs-5 mb-3">{cat.catTitle}</h4>
                                <div className="position-relative">
                                    <Link to={cat.catLink} target="_blank">
                                        <GatsbyImage className="catalogueThumbnail" image={loadedImage} alt={index} />
                                    </Link>
                                    <div className="position-absolute bottom-0 end-0">
                                        <Link to={cat.catLink} target="_blank" className="btn  btn-primary rounded-0 m-3">View catalogue</Link>
                                    </div>
                                </div>
                            </div>
                        )
                                
                            
                        })
                        ) : (
                        <>
                            
                        </>
                )}
                </div>
            </div>
    );
  }
}